<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { useI18n } from 'vue-i18n';
import { lockBackgroundScroll, unLockBackgroundScroll } from '@/utils/utils';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { getCookie, setCookie } from 'typescript-cookie';
import { LOCALE } from '../../types';
import { loadLocalData } from '@/utils/common-util';
import AppConfig from '@/constants';

export default defineComponent({
	name: 'ApocRenewalPopup',
	components: { ApocImageSet },
	emits: ['close', 'close-day'],
	setup(props, { emit }) {
		const storeManager = initStore();
		const { t } = useI18n();
		const loadedImageCnt = ref<number>(0);
		const lang = ref<string | null>(null);

		const onClickClose24h = () => {
			emit('close-day');
			// setCookie(`renewal_popup_close_flag`, 'close', { expires: 1 });
			// closePopup();
		};
		const closePopup = () => {
			emit('close');
		};

		const goToCommunity = () => {
			let boardIdx = '';
			if (lang.value === LOCALE.KO) {
				boardIdx = 'b5daa0fc-3447-4b20-b1a5-f7b50fc24a17';
			} else {
				boardIdx = '1bc022a1-60f9-41f3-8812-f9bc3d0a6893';
			}
			window.location.href = `https://comm.apoc.day/board/notice/${boardIdx}?type=NOTICE`;
			closePopup();
		};

		const handleImageLoaded = () => {
			loadedImageCnt.value++;
		};

		const closeAllPopup = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		onMounted(() => {
			lang.value = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
			// if (getCookie(`renewal_popup_close_flag`) === 'close') closePopup();
			// else {
			// 	lockBackgroundScroll();
			// }
		});

		onBeforeUnmount(() => {
			unLockBackgroundScroll();
		});

		return {
			t,
			loadedImageCnt,
			closePopup,
			onClickClose24h,
			goToCommunity,
			handleImageLoaded,
			closeAllPopup,
		};
	},
});
</script>

<template>
	<div class="apoc-renewal-popup" :class="{ loaded: loadedImageCnt >= 5 }" v-click->
		<div class="apoc-renewal-popup-wrapper">
			<div class="content-area">
				<div class="popup-bg">
					<div v-for="bgEl of 5" :key="bgEl" :class="`bg-el-${bgEl}`" />
				</div>
				<div class="logo-update">
					<apoc-image-set src="assets/images/popup/prev-logo.webp" class="prev-logo" @load="handleImageLoaded" />
					<apoc-image-set src="assets/images/popup/logo-change-icon.webp" class="change-icon" @load="handleImageLoaded" />
					<div class="renewal-logo-wrapper">
						<apoc-image-set src="assets/images/popup/renewal-logo.webp" class="renewal-logo" @load="handleImageLoaded" />
						<apoc-image-set src="assets/images/popup/logo-deco.webp" class="logo-deco" @load="handleImageLoaded" />
						<apoc-image-set src="assets/images/popup/logo-deco-mobile.webp" class="logo-deco mobile" @load="handleImageLoaded" />
					</div>
				</div>
				<div class="main-title">{{ t('popup.renewalPopup.title') }}</div>
				<div class="desc" v-html="t('popup.renewalPopup.desc')"></div>
				<div class="desc mobile" v-html="t('popup.renewalPopup.desc-mobile')"></div>
				<div class="comm-link-btn" @click="goToCommunity">{{ t('popup.renewalPopup.commBtn') }} ></div>
			</div>
			<div class="btn-area">
				<div class="btn btn-left" @click="onClickClose24h">{{ t('popup.renewalPopup.close24h') }}</div>
				<div class="btn btn-right" @click="closePopup">{{ t('popup.renewalPopup.close') }}</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
