<script lang="ts">
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { getCookie, setCookie } from 'typescript-cookie';
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AppConfig from '@/constants';
import { lockBackgroundScroll, unLockBackgroundScroll } from '@/utils/utils';
import ApocImageSet from '@/components/common/ApocImageSet.vue';

export default defineComponent({
	name: 'ApocPromoPopup',
	components: { ApocImageSet },
	emits: ['close', 'close-day'],
	setup(props, { emit }) {
		const storeManager = initStore();
		const { t } = useI18n();
		const loadedImageCnt = ref<number>(0);

		const onClickClose24h = () => {
			emit('close-day');
			// setCookie(`renewal_popup_close_flag`, 'close', { expires: 1 });
			// closePopup();
		};

		const closePopup = () => {
			emit('close');
			// storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		const handleImageLoaded = () => {
			loadedImageCnt.value++;
		};

		const goToPromo = () => {
			// 삼성물산 페이지로 이동
			window.open('https://www.apoc.day/heycomehere');
		};

		onMounted(() => {
			// if (getCookie(`renewal_popup_close_flag`) === 'close') closePopup();
			// else lockBackgroundScroll();
		});

		onBeforeUnmount(() => {
			unLockBackgroundScroll();
		});

		return {
			t,
			loadedImageCnt,
			goToPromo,
			closePopup,
			onClickClose24h,
			handleImageLoaded,
		};
	},
});
</script>

<template>
	<div class="apoc-promo-popup" :class="{ loaded: loadedImageCnt >= 1 }">
		<div class="apoc-promo-popup-wrapper">
			<div class="content-area">
				<div class="popup-bg">
					<apoc-image-set class="promo-img" src="assets/images/popup/apoc-promo-img.webp" @load="handleImageLoaded" />
				</div>
				<div class="comm-link-btn" @click="goToPromo">{{ t('popup.promoPopup.commBtn') }} ></div>
			</div>
			<div class="btn-area">
				<div class="btn btn-left" @click="onClickClose24h">{{ t('popup.renewalPopup.close24h') }}</div>
				<div class="btn btn-right" @click="closePopup">{{ t('popup.renewalPopup.close') }}</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
