import { ResponseDto } from '@/api/dto/response.dto';
import AppConfig from '@/constants';
import { StoreManager } from '@/stores/store-manager';
import { AxiosInstance } from 'axios';
import { getApiClient, setApiToken } from './apiClient';
import { loadLocalData, removeLocalData, saveLocalData } from './common-util';

// sso login
export const ssoLogin = (isStudio: boolean = false, idx: string = '') => {
	// const redirectUrl = isStudio
	// 	? `${AppConfig.API_SERVER}/users/ssoLogin/studio`
	// 	: `${AppConfig.API_SERVER}/users/ssoLogin` + (idx !== '' && `?idx=${idx}`);

	let redirectUrl = `${AppConfig.API_SERVER}/users/ssoLogin`;

	if (isStudio) redirectUrl = `${AppConfig.API_SERVER}/users/ssoLogin/studio`;
	else if (idx !== '') redirectUrl = `${AppConfig.API_SERVER}/users/ssoLogin/${idx}`;

	const encodedRedirectUrl = btoa(redirectUrl);

	// 언어 설정
	const lang = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG) || 'KO';

	// URL 파라미터를 추가하여 이동
	const url = new URL(`${AppConfig.SSO_API_SERVER}/auth/login`);
	url.searchParams.append('ru', encodedRedirectUrl); // redirect url
	url.searchParams.append('sc', 'APOC'); // servie code (play => APOC)
	url.searchParams.append('lang', lang); // lang (기본 KO)

	window.location.href = url.toString();
};

// sso logout
export const ssoLogout = (storeManager: StoreManager) => {
	removeLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
	removeLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
	storeManager.dataStore.setAuthToken('');
	setApiToken('');
	window.location.href = `${AppConfig.SSO_API_SERVER}/auth/logout?type=new&re=${btoa(AppConfig.FRONT_HOST)}`; //'http://localhost:4100/auth/logout'
};

// sso 자동 로그인 체크
export const getSsoUser = async (storeManager: StoreManager) => {
	const apiClient = getApiClient(AppConfig.API_SERVER);

	if (loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN) && loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER)) return;
	await fetch(`${AppConfig.SSO_API_SERVER}/api/auth?sc=APOC`, { method: 'GET', credentials: 'include' })
		.then(res => res.json())
		.then(res => {
			ssoUserCheck(apiClient, { code: res.code, data: res.data, msg: res.msg }).then(res => {
				if (res.data) {
					const { userInfo, token } = res.data;
					saveLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN, token);
					saveLocalData(AppConfig.KEYS.CONST.LOGIN_USER, JSON.stringify(userInfo));
					setApiToken(token);
					storeManager.dataStore.setAuthToken(token);
					location.replace('');
				}
			});
		})
		.catch(e => {
			console.error(e);
		});
};

export function ssoUserCheck(client: AxiosInstance, param: any): Promise<ResponseDto<any>> {
	const promiseFn = (fnResolve: (value: ResponseDto<any>) => void, fnReject: (reason?: any) => void) => {
		client
			.get('/users/ssoUserCheck', {
				headers: { credentials: 'include' },
				params: param,
			})
			.then(res => {
				fnResolve(new ResponseDto<any>(res.data));
			})
			.catch(err => {
				console.error(err);
				fnReject('msg.RESULT_FAILED');
			});
	};
	return new Promise(promiseFn);
}
